import React, {useEffect, useState} from 'react';
import Header from './components/Header';
import InformationCard from './components/InformationCard';
import LanguageOptionsTraders from './components/LanguageOptionsTraders';
import Footer from './components/Footer';
import { useAppContext } from './Provider/AppProvider';
import SignUp from './components/SignUp';


const content1 = {
  title: "Job Function & Responsibilities: ",
  content: [
           "- We are seeking a talented and self-motivated Experienced Trader to join our growing trading team",
           "- The ideal candidate is willing to develop new trading strategies utilizing our proprietary software, as well as customize our current trading strategies",
      ]
}

const content2 = {
  title: "Required Experience & Skills:",
  content: [
           "- Strong mathematical aptitude",
           "- Values teamwork and is capable of thinking independently",
           "- Can communicate effectively while under pressure",
           "- Can adapt to changing situations with ease",
           "- 5+ years working experience of high volume trading",
           "- Has demonstrated the ability to use sound judgment while trading",
           "- Proven ability to develop new, profitable trading strategies",

      ]
}

const companyURL="https://xrtrading.com/";

function AppTraderAwesome2() {
  const { state, setData } = useAppContext();
  const [showModal, setShowModal] = useState(true);

  useEffect(()=>{
    const newData = { companyURL }; // Example new data
    setData(newData);
    localStorage.setItem("company", companyURL);
    
  },[])

  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className=" py-10">
        <div className="w-3/4 mx-auto flex space-x-6 flex-col md:flex-row">
          <InformationCard location="Fully Remote • United States" salary="$72K - $132K/Yr & PnL" company="XR Trading" companyURL={companyURL} />
          <LanguageOptionsTraders company="XR Trading" title="Remote EXPERIENCED TRADER" content1={content1} content2={content2}/>
        </div>
      </main>
      <Footer />
      {
        showModal? <SignUp callback={()=>setShowModal(false)}/>
        :<></>
      }
    </div>
  );
}

export default AppTraderAwesome2;
