import React, {useEffect, useState} from 'react';
import Header from './components/Header';
import InformationCard from './components/InformationCard';
import LanguageOptionsTraders from './components/LanguageOptionsTraders';
import Footer from './components/Footer';
import { useAppContext } from './Provider/AppProvider';
import SignUp from './components/SignUp';


const content1 = {
  title: "Responsibilities:",
  content: [
          "- Develop and execute market-making strategies across various crypto assets to provide liquidity and enhance market efficiency.",
          "- Continuously monitor and analyze market conditions, adjusting trading strategies to optimize profitability and minimize risks.",
          "- Utilize advanced quantitative models and algorithms to make informed trading decisions.",
          "- Collaborate closely with the technology team to develop and improve trading systems and tools.",
          "- Manage the trading book, ensuring accurate and timely execution of trades and proper risk management practices.",
          "- Implement and oversee risk control strategies to mitigate potential market and operational risks.",
          "- Provide real-time market insights and analysis to the investment management team.",
          "- Maintain compliance with industry regulations and internal policies, ensuring all trading activities are conducted ethically and legally.",
          "- Participate in the development of new trading strategies and contribute to the overall growth of the trading desk."
    ]
}

const content2 = {
  title: "Key Requirements:",
  content: [
            "- Bachelor’s or Master’s degree in Finance, Economics, Mathematics, or a related field from a renowned university.",
            "- Significant experience in a trading role within top name exchanges, trading firms, or hedge funds, with a focus on market making.",
            "- In-depth knowledge of cryptocurrency markets and blockchain technology.",
            "- Expertise in the development and execution of quantitative trading strategies.",
            "- Self-motivated and eager to be successful.",
            "- Experienced in using trading platforms and tools, with a deep understanding of market microstructure.",
            "- Strong background in risk management and compliance within financial services.",
            "- Ability to work under pressure in a fast-paced trading environment.",
    ]
}

const companyURL="https://hyphen-connect.com/";


function AppMaxwell() {
  const { state, setData } = useAppContext();
  const [showModal, setShowModal] = useState(true);

  useEffect(()=>{
    const newData = { companyURL }; // Example new data
    setData(newData);
    localStorage.setItem("company", companyURL);
    
  },[])

  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className=" py-10">
        <div className="w-3/4 mx-auto flex space-x-6 flex-col md:flex-row">
          <InformationCard location="United States (Remote)" salary="$150,000 + $200,000/y + bonus & PnL" company="Hyphen Connect" companyURL={companyURL} />
          <LanguageOptionsTraders company="Hyphen Connect" title="Quant Trader – Remote" content1={content1} content2={content2}/>
        </div>
      </main>
      <Footer />
       {
        showModal? <SignUp callback={()=>setShowModal(false)}/>
        :<></>
      }
    </div>
  );
}

export default AppMaxwell;
