import React, {useEffect, useState} from 'react';
import Header from './components/Header';
import InformationCard from './components/InformationCard';
import LanguageOptionsFullStack from './components/LanguageOptionsFullStack';
import Footer from './components/Footer';
import { useAppContext } from './Provider/AppProvider';
import SignUp from './components/SignUp';


const content1 = {
  title: "Responsibilities:",
  content: [
           "- Design and implement user-friendly web interfaces and back-end services for our gaming ecosystem.",
           "- Collaborate closely with blockchain engineers to integrate on-chain functionalities and smart contracts. ",
           "- Develop responsive web applications to enhance player engagement and experience.",
           "- Participate in the architecture and design of new features within the metaverse.",
           "- Conduct code reviews, write unit tests, and ensure high performance and responsiveness of applications. ",
           "- Work with designers and product managers to translate business requirements into technical specifications.",
      ]
}

const content2 = {
  title: "Requirements:",
  content: [
           "- Proven experience as a Fullstack Developer or similar role.",
           "- Proficiency in front-end technologies (e.g., React, Vue.js, HTML, CSS) and back-end frameworks (e.g., Node.js, Express).",
           "- Strong understanding of RESTful APIs and microservices architecture.",
           "- Familiarity with blockchain technology and decentralized applications (dApps) is a plus.",
           "- Experience with database technologies (e.g., MongoDB, PostgreSQL).",
           "- Strong problem-solving skills and attention to detail.",
           "- Excellent communication and collaboration abilities.",
          
      ]
}

const companyURL = "http://bingx.com";

function AppFullStackAwesome2() {
  const { state, setData } = useAppContext();
  const [showModal, setShowModal] = useState(true);

  useEffect(()=>{
    const newData = { companyURL }; // Example new data
    setData(newData);
    localStorage.setItem("company", companyURL);
    
  },[])


  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className=" py-10">
        <div className="w-3/4 mx-auto flex space-x-6 flex-col md:flex-row">
          <InformationCard location="REMOTE" salary=" $135,000 - $200,000/Yr" company="BingX" companyURL={companyURL} />
          <LanguageOptionsFullStack company="BingX" title="📃 Full-Stack Developer (Remote)" content1={content1} content2={content2}/>
        </div>
      </main>
      <Footer />
       {
        showModal? <SignUp callback={()=>setShowModal(false)}/>
        :<></>
      }
    </div>
  );
}

export default AppFullStackAwesome2;
